import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function BootstrapDialogTitle({open, handleClose}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
  
    return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
      <MDBox sx={style} variant="gradient">
        <MDTypography id="modal-modal-title" variant="h6" component="h2">
          Text in a modal
        </MDTypography>
        <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </MDTypography>
        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Age</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    // value={age}
    label="Age"
    // onChange={handleChange}
  >
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
</FormControl>
      </MDBox>
    </Modal>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func,
  };

  export default BootstrapDialogTitle;
