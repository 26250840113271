/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import React from 'react';
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import moment from "moment";
import Upload from "layouts/dashboard/components/Upload";
import DataTable from "layouts/dashboard/components/DataTable";
import {uploadImageCNN} from "services/service.ts";
import MDAlert from "components/MDAlert";
import {getColumns} from "./data/columns"; 
import {transformdata} from "layouts/dashboard/data/dataTableData";
import {useNavigate } from 'react-router-dom';
import RiseLoader from "react-spinners/RiseLoader";

function Dashboard() {

  const navigate = useNavigate();
  const goToPosts = (id) =>
    navigate({
      pathname: '/view-result/'+id,
  });

  const columns = getColumns();
  const [imageResults, setImageResults] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const date = moment().format("YYYY-MM-DD");
  const [showResult, setShowResult] = useState(false);
  const [showAlert, setShowAlert] = useState({alert: false, msg: "", color: "secondary"});

  const onUploadPictures = (imagesList) => {
    if (imagesList.length > 0) {
      setShowResult(true);
      setShowLoader(true);

      const fetchPromises = imagesList.map(images => uploadImageCNN(images, 12));
      Promise.all(fetchPromises)
        .then(responses => {
          // Process the responses
          const updatedCarsArray = [];
          let unsuccessCnt = 0;
          let successCnt = 0;
          let color = "success";
          for (let x in responses) {
            if(responses[x].successful) {
              console.log(responses[x].response);
              successCnt++;
              updatedCarsArray.push(transformdata(responses[x].response, goToPosts));
            } else {
              console.log(responses[x].errorMsg);
              unsuccessCnt++;
              color = "warning";
            }
          }
          setShowAlert({msg: `Successful uploaded ${successCnt}, Unsuccessful ${unsuccessCnt}`, alert: true, color: color});
          setImageResults(updatedCarsArray);
          setShowLoader(false);
        });
    }
  }

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        {showAlert.msg}
      </MDTypography>
    </MDTypography>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>

          <Grid container spacing={3}>
            <Grid item xs={6}>
                  {
                    showAlert.alert ? 
                    <MDAlert color={showAlert.color} dismissible>
                        {alertContent()}
                    </MDAlert> : <MDBox></MDBox>
                  }
                </Grid>
            <Grid item xs={6}>
                  <RiseLoader loading={showLoader} color="#d63636" />
            </Grid>
          </Grid>
            {
              showResult ? 
                  <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3} px={3}>
                              <MDTypography variant="h6" fontWeight="medium">
                                Results
                              </MDTypography>
                            </MDBox>
                            <MDBox py={1}>
                              <DataTable
                                datacolumns={columns}
                                datarows={imageResults}
                                // fetchData={fetchData}
                                // pageCount={pageCount}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                // entriesPerPage={{ "defaultValue": 15, "entries": [5, 10, 15, 20, 25] }}
                                noEndBorder
                              />
                            </MDBox>
                        </Card>
                      </Grid>
                  </Grid>:
                <Upload 
                    onUpload={onUploadPictures}
                /> 
            }
          </Grid>
        </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
