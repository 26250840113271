/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import IconButton from "@mui/material/IconButton";
import { HandymanOutlined} from '@mui/icons-material';

export default function transformdata(data, goToPosts) {
  const Job = ({ title, description }) => (
    <MDBox lineHeight={2} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography display="block" variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return data.map(function(res) {
        return {
          // author: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
          email:(
            <MDTypography component="a" href={"/view-user/"+res.id} variant="caption" color="text" fontWeight="medium">
              {res.email}
            </MDTypography>
          ),
          names: (
            <Job title={res.firstName}  description={res.secondName} />
          ),
          userType:(
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.userType}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDBadge badgeContent={res.active} color={res.active == "Active" ? "success" : "warning"} variant="gradient" size="lg" />
            </MDBox>
          ),
          action: (
            <MDTypography component="a" href="#" color="text">
              {
                res.userType != "Admin" ?
                <IconButton
                  size="small"
                  disableRipple
                  onClick={ () => goToPosts(res.id) }
                  >
                  <HandymanOutlined fontSize="small">
                    View
                  </HandymanOutlined>
                </IconButton> : <MDTypography></MDTypography>
              }
            </MDTypography>
          ),
        }
    });
}
