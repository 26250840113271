export default function getColumns() {
    return [
      { Header: "description", accessor: "description", align: "left" },
      { Header: "subject", accessor: "subject", align: "left" },
      { Header: "tutor", accessor: "tutor", align: "left" },
      { Header: "when", accessor: "when", align: "left" },
      { Header: "startdate", accessor: "startdate", align: "left" },
      { Header: "enddate", accessor: "enddate", align: "left" },
      { Header: "status", accessor: "status", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ];
}