/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components
import ProductCell from "layouts/dashboard/components/ProductCell";
import RefundsCell from "layouts/dashboard/components/RefundsCell";
import DefaultCell from "layouts/dashboard/components/DefaultCell";
import MDBadge from "components/MDBadge";

export function transformdata(data, goToPosts) {
  return {
          // status: <DefaultCell>{data.status}</DefaultCell>,
      result: <ProductCell image={data.imagePath} name={data.id} goToResult={goToPosts} />,
      // status: <DefaultCell>{data.status}</DefaultCell>,
      status: <MDBadge variant="contained" color="success" badgeContent={data.status} container />,
      diseaseName: <DefaultCell>{data.diseaseName}</DefaultCell>,
      createdDate: <DefaultCell>{data.createdDate}</DefaultCell>,
      // refunds: <RefundsCell value={13} icon={{ color: "success", name: "keyboard_arrow_up" }} />,
    };
}

export function transformAdmindata(data, goToPosts) {
  return {
      user: <RefundsCell value={data.userEmail} icon={{ color: "success", name: "keyboard_arrow_up" }} />,
      result: <ProductCell image={data.imagePath} name={data.id} goToResult={goToPosts} />,
      // status: <DefaultCell>{data.status}</DefaultCell>,
      status: <MDBadge variant="contained" color="success" badgeContent={data.status} container />,
      diseaseName: <DefaultCell>{data.diseaseName}</DefaultCell>,
      createdDate: <DefaultCell>{data.createdDate}</DefaultCell>,
      // refunds: <RefundsCell value={13} icon={{ color: "success", name: "keyboard_arrow_up" }} />,
    };
}