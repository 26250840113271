/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import  React from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CarCard from "examples/Cards/CarCard";

// Overview page components
import Header from "components/Header";
import {useParams } from 'react-router-dom';
import {getResultByID} from "services/service.ts";

import ProductImages from "layouts/viewresult/components/ProductImages";
import ProductInfo from "layouts/viewresult/components/ProductInfo";

function ViewResult() {

  const [isRefreshing, setRefreshing] = React.useState(false);
  const [data, setData] = React.useState(null);
  const fetchIdRef = React.useRef(0);

  let { id } = useParams();

  React.useEffect(() => {
    fetchData(id)
  }, [id])


  const fetchData = React.useCallback(async (id) => {
    const fetchId = ++fetchIdRef.current;

    setRefreshing(true);

    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
        const resultData = await getResultByID(id);
        
        if(resultData.successful) {
          console.log(resultData.response);
          setData(resultData.response);
        } else {
          console.log(resultData.errorMsg.status);
        }
        setRefreshing(false);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2}>

      <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox mb={3}>
              <MDTypography variant="h5" fontWeight="medium">
                Results Details
              </MDTypography>
            </MDBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
              {data != null ? <ProductImages data={data} /> : <MDTypography></MDTypography>}
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
              {data != null ? <ProductInfo data={data} /> : <MDTypography></MDTypography>}
              </Grid>
            </Grid>

          </MDBox>
        </Card>

      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ViewResult;
