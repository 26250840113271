export function isAuth() {
    const tokenString = localStorage.getItem('onescan-token');
    const userToken = JSON.parse(tokenString);
    if (userToken) {
        const base64Url = userToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        const { exp } = JSON.parse(jsonPayload);
        const expired = (exp * 1000) > Date.now()
        return expired
    }
    return false;
};

export function getToken() {
    const tokenString = localStorage.getItem('onescan-token');
    const userToken = JSON.parse(tokenString);
    return userToken;
};

export function getUserType() {
    return JSON.parse(localStorage.getItem('onescan-userType'));
};

export function getEmail() {
    return JSON.parse(localStorage.getItem('onescan-email'));
};

export function saveToken(user) {
    localStorage.setItem('onescan-token', JSON.stringify(user.token));
    localStorage.setItem('onescan-email', JSON.stringify(user.email));
    localStorage.setItem('onescan-userType', JSON.stringify(user.userType));
};

export function deleteToken() {
    localStorage.removeItem('onescan-token');
    localStorage.removeItem('onescan-email');
    localStorage.removeItem('onescan-userType');
};