/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import moment from "moment";


const defaultLineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Facebook Ads",
      color: "info",
      data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
    },
    {
      label: "Google Ads",
      color: "dark",
      data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
    },
  ],
};

function transFormData(data) {
  const labels = [];
  const lines = [];
  for (let k in data) {
    const i = moment(data[k].key, "YYYY-MM-DDThh:mm:ss").format("YYYY-MM-DD");
    labels.push(i);
    lines.push(data[k].value);
  }
  return {
    labels: labels,
    datasets: [
      {
        label: "Uploads",
        color: "info",
        data: lines,
      },
    ],
  }
}

export default transFormData;
