import React from 'react';
import ImageUploading from 'react-images-uploading';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import BookingCard from "examples/Cards/BookingCard";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { AddAPhotoOutlined,InfoRounded, SwapHorizontalCircle, DeleteForever, GroupRemoveOutlined, FileUpload } from '@mui/icons-material';


function Upload({onUpload}) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 6;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const onUploadImages = (images) => {
    onUpload(images)
  }

  const createAction = (index, onImageRemove, onImageUpdate) => {
    return  <>
        <Tooltip title="Update" placement="bottom">
          <MDTypography
            variant="body1"
            color="primary"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 3 }}
          >
            <SwapHorizontalCircle onClick={() => onImageUpdate(index)} color="inherit">update</SwapHorizontalCircle>
          </MDTypography>
        </Tooltip>
        <Tooltip title="Remove" placement="bottom">
          <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
            <DeleteForever onClick={() => onImageRemove(index)} color="inherit">remove</DeleteForever>
          </MDTypography>
        </Tooltip>
      </>
  }

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <InfoRounded fontSize="medium" color="inherit">
            language
          </InfoRounded>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          Upload New Images
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
          
          <MDBox pb={3}>
              <Grid alignItems="left">
                      <MDButton variant="gradient" color="info" onClick={onImageUpload} >
                      <AddAPhotoOutlined>add</AddAPhotoOutlined>&nbsp; Add New
                      </MDButton>
                      &nbsp;
                      <MDButton variant="gradient" color="info" onClick={onImageRemoveAll} >
                      <GroupRemoveOutlined>add</GroupRemoveOutlined>&nbsp; Remove all images
                      </MDButton>
                      &nbsp;
                      <MDButton variant="gradient" color="info" onClick={ () => onUploadImages(images)} >
                        <FileUpload>add</FileUpload>
                        &nbsp; Upload All
                      </MDButton>
              </Grid>
          </MDBox>

                <MDBox mt={2}>
                  <Grid container spacing={3}>
                      {imageList.map((image, index) => (
                            <Grid key={index} item xs={12} md={6} lg={4}>
                                <MDBox key={index} mt={3}>
                                    <BookingCard
                                      key={index}
                                      image={image['data_url']}
                                      title="Cozy 5 Stars Apartment"
                                      action={createAction(index, onImageRemove, onImageUpdate)}
                                    />
                                </MDBox>
                            </Grid>

                      ))}
                  </Grid>
                </MDBox>

          </div>
        )}
      </ImageUploading>
        </Grid>
      </MDBox>
    </Card>
  );
}


Upload.propTypes = {
  onUpload: PropTypes.func,
};

export default Upload;