/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import DataTable from "examples/Tables/DataTable";
import {transformAdmindata} from "layouts/dashboard/data/dataTableData";

// Sales dashboard components
import ChannelsChart from "layouts/insight/components/ChannelsChart";
import {getAdminColumns} from "layouts/dashboard/data/columns";
import {totalUpload, totalUser, detectionBreakdown, uploadOvertime, listUploadsAdmin } from "services/service.ts";
import {useNavigate } from 'react-router-dom';
import moment from "moment";

// Data
import transFormData from "layouts/insight/data/defaultLineChartData";

function Insight() {
  const navigate = useNavigate();
  const goToPosts = (id) =>
    navigate({
      pathname: '/view-result/'+id,
  });

  const [totalUsers, setTotalUser] = useState(0);
  const [totalUploads, setTotalUploads] = useState(0);
  const [breakdownData, setBreakdownData] = useState([]);
  const [uploadOvertimes, setUploadOvertime] = useState({
      labels: ["APR"],
      datasets: [
        {
          label: "Uploads",
          color: "info",
          data: [10],
        },
      ],
  });

  const columns = getAdminColumns();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isRefreshing, setRefreshing] = useState(false);

  const date = moment().format("YYYY-MM-DD");
  const [endDate, setEndDate] = useState(moment().add(1, 'days'));
  const [startDate, setStartDate] = useState(moment().subtract(1, 'years'));

  const fetchIdRef = useRef(0);

  useEffect(() => {
    const startDateStr = startDate.format("YYYY-MM-DD");
    const endDateStr = endDate.format("YYYY-MM-DD");
    console.log(startDateStr + " = " + endDateStr);

    totalUpload(startDateStr, endDateStr).then(myData => { 
        if(myData.successful) {
          setTotalUploads(myData.result.value)
        } else {
          console.log("error");
        }
    });

    totalUser(startDateStr, endDateStr).then(myData => { 
        if(myData.successful) {
          setTotalUser(myData.result.value)
        } else {
          console.log("error");
        }
    });

    detectionBreakdown(startDateStr, endDateStr).then(myData => { 
          if(myData.successful) {
            setBreakdownData(myData.results)
          } else {
            console.log("error");
          }
    });
    uploadOvertime(startDateStr, endDateStr).then(myData => { 
            if(myData.successful) {
              setUploadOvertime(transFormData(myData.results))
            } else {
              console.log("error");
            }
     });
    // Correct! Runs once after render with empty array
  }, []); 

  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    const fetchId = ++fetchIdRef.current;
    // Set the loading state
    setRefreshing(true);

    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        // console.log(pageSize, startRow, pageIndex);
        const uploadData = await listUploadsAdmin(pageSize, startRow, "");
        
        if(uploadData.successful) {
          // console.log(reservationData.reservations);
          const updatedCarsArray = [];
          for (let x in uploadData.response) {
            // console.log(uploadData.response[x]);
            updatedCarsArray.push(transformAdmindata(uploadData.response[x], goToPosts));
          }
          setData(updatedCarsArray);
          setPageCount(Math.ceil(uploadData.total / pageSize));
          setRefreshing(false);
        } else {
          console.log(uploadData.errorMsg.status);
          setRefreshing(false);
        }
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Total users"
                count={totalUsers}
                percentage={{
                  color: "success",
                  value: "+55%",
                  label: "since last month",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Total uploads"
                count={totalUploads}
                percentage={{
                  color: "success",
                  value: "+12%",
                  label: "since last month",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="average daily upload"
                count="$1.200"
                percentage={{
                  color: "secondary",
                  value: "+$213",
                  label: "since last month",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <ChannelsChart data={breakdownData} />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <DefaultLineChart
                title="Upload overtime"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Uploads" />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="See which ads perform better" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={uploadOvertimes}
              />
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  All Results
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
              <DataTable
                      datacolumns={columns}
                      datarows={data}
                      fetchData={fetchData}
                      pageCount={pageCount}
                      isSorted={false}
                      showTotalEntries={true}
                      noEndBorder
                      entriesPerPage={{ "defaultValue": 10, "entries": [5, 10, 15, 20, 25] }}
                    />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Insight;
