/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const channelChartData = {
  labels: ["Facebook", "Direct", "Organic", "Referral"],
  datasets: {
    label: "Projects",
    backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
    data: [15, 20, 12, 60],
  },
};

function transformdata(data) {
  const labels = [];
  const lines = [];
  for(let i in data) {
    labels.push(data[i].key);
    lines.push(data[i].value+1);
  }
  return {
    labels: labels,
    datasets: {
      label: " of uploads",
      // backgroundColor: [
      //   'rgba(255, 99, 132, 0.2)',
      //   'rgba(54, 162, 235, 0.2)',
      //   'rgba(255, 206, 86, 0.2)',
      //   'rgba(75, 192, 192, 0.2)',
      //   'rgba(153, 102, 255, 0.2)',
      //   'rgba(255, 159, 64, 0.2)',
      //   'rgba(255, 3, 64, 0.2)',
      // ],
      backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
      data: lines,
      borderWidth: 1,
    },
  }
}

export default transformdata;
