import { PlatformUser } from "../model/User";
import moment from "moment";
import { Subject} from "../model/Metadata";
import { PredictionAdminResponse, PredictionResponse } from "model/PredictionRequest";

export function formatDateTime(time: any): string {
  return moment(time)
  .utc()
  .format("ddd MMM DD GGGG, h:mm A")
}

export function distance(time: any): string {
  return moment(time).fromNow();
}

export function parseSubject(epc: any): Subject {
  const cat: Subject = {
      id: epc["id"],
      name: epc["name"],
      createDate: formatDateTime(epc["created_date"]),
  };

  return cat;
}


export function parseUser(epc: any): PlatformUser {
  const platformUser: PlatformUser = {
      id: epc["id"],
      email: epc["email"],
      firstName: epc["first_name"],
      secondName: epc["second_name"],
      dob: epc["dob"],
      userType: epc["is_admin"] ? "Admin" : "Client",
      active: epc["active"] ? "Active": "Inactive",
      createDate: epc["create_date"],
  };

  return platformUser;
}

export function parsePredictionResponse(host:string, dataJsonForm: any): PredictionResponse {
  const res: PredictionResponse = {
    id: dataJsonForm["id"],
    imagePath: `${host}/api/v1/images?name=`+dataJsonForm["image_path"],
    status: dataJsonForm["status"],
    result: dataJsonForm["result"],
    createdDate: formatDateTime(dataJsonForm["create_date"]),
    shortName: dataJsonForm["disease_short_code"],
    diseaseName: dataJsonForm["disease_full_name"],
    fullDescription: dataJsonForm["disease_description"],
  };

  return res;
}

export function parsePredictionAdminResponse(host:string, dataJsonForm: any): PredictionAdminResponse {
  const res: PredictionAdminResponse = {
    id: dataJsonForm["id"],
    imagePath: `${host}/api/v1/images?name=`+dataJsonForm["image_path"],
    status: dataJsonForm["status"],
    result: dataJsonForm["result"],
    createdDate: formatDateTime(dataJsonForm["create_date"]),
    shortName: dataJsonForm["disease_short_code"],
    diseaseName: dataJsonForm["disease_full_name"],
    fullDescription: dataJsonForm["disease_description"],
    userEmail: dataJsonForm["user_email"],
    userID: dataJsonForm["user_id"],
    userName: dataJsonForm["user_names"],
  };

  return res;
}