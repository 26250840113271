import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import BootstrapDialogTitle from "layouts/myuploads/components";
import {listUploads} from "services/service.ts";
import MDButton from "components/MDButton";

// Data
import {transformdata} from "layouts/dashboard/data/dataTableData";
import {getColumns} from "layouts/dashboard/data/columns";
import {useNavigate } from 'react-router-dom';

function MyUploads() {

  const fetchIdRef = React.useRef(0);
  const [data, setData] = React.useState([]);
  const [isRefreshing, setRefreshing] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const navigate = useNavigate();
  const goToPosts = (id) =>
    navigate({
      pathname: '/view-result/'+id,
  });

  const columns = getColumns();

  const fetchData = React.useCallback(async ({ pageIndex, pageSize }) => {
    const fetchId = ++fetchIdRef.current;
    // Set the loading state
    setRefreshing(true);

    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        console.log(pageSize, startRow, pageIndex);
        const uploadData = await listUploads(pageSize, startRow);
        
        if(uploadData.successful) {
          // console.log(reservationData.reservations);
          const updatedCarsArray = [];
          for (let x in uploadData.response) {
            console.log(uploadData.response[x]);
            updatedCarsArray.push(transformdata(uploadData.response[x], goToPosts));
          }
          setData(updatedCarsArray);
          setPageCount(Math.ceil(uploadData.total / pageSize));
          setRefreshing(false);
        } else {
          console.log(uploadData.errorMsg.status);
          setRefreshing(false);
        }
    }
  }, []);

  return (
      <DashboardLayout>
        <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
              <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                <MDBox display="flex">
                  <MDBox ml={1}>
                  </MDBox>
                </MDBox>
              </MDBox>
                <Card>
                  <MDBox pt={3}>
                    <DataTable
                      datacolumns={columns}
                      datarows={data}
                      fetchData={fetchData}
                      pageCount={pageCount}
                      isSorted={false}
                      showTotalEntries={true}
                      noEndBorder
                      entriesPerPage={{ "defaultValue": 10, "entries": [5, 10, 15, 20, 25] }}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        <Footer />
      </DashboardLayout>
  )
}

export default MyUploads;
