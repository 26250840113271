/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import  React from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Overview page components
import Header from "components/Header";
import {useParams } from 'react-router-dom';
import {listUploadsAdmin} from "services/service.ts";

import transformdata from "layouts/viewuser/data/authorsTableData";
import getColumns from "layouts/viewuser/data/columns";
import { ImageGallery } from "react-image-grid-gallery";
import { images as IMAGES } from "layouts/viewuser/data/images";

function ViewUser() {

  const [isRefreshing, setRefreshing] = React.useState(false);
  const [data, setData] = React.useState([]);
  const fetchIdRef = React.useRef(0);
  const [pageCount, setPageCount] = React.useState(0);

  let { id } = useParams();

  React.useEffect(() => {
    fetchData(id)
  }, [id])

  const columns = getColumns();

  const fetchData = React.useCallback(async (id) => {
    const fetchId = ++fetchIdRef.current;

    setRefreshing(true);

    if (fetchId === fetchIdRef.current) {
      console.log(id);
      const uploadData = await listUploadsAdmin(20, 0, id);

      if(uploadData.successful) {
        // console.log(reservationData.reservations);
        const updatedCarsArray = [];
        for (let x in uploadData.response) {
          // console.log(uploadData.response[x]);
          updatedCarsArray.push({
            alt: uploadData.response[x].diseaseName,
            caption: uploadData.response[x].diseaseName,
            src: uploadData.response[x].imagePath,
          });
        }
        setData(updatedCarsArray);
        setRefreshing(false);
      } else {
        console.log(studentData.errorMsg.status);
        setRefreshing(false);
      }
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox pt={2} px={2} lineHeight={1.25}>
        </MDBox>
        <MDBox p={2}>
        <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                   Results
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <div
                    style={{
                      display: "block",
                      minHeight: "1px",
                      width: "100%",
                      border: "1px solid #ddd",
                      overflow: "auto"}}
                  >
                  </div>
                  <ImageGallery       imagesInfoArray={data}
      columnWidth={230}
      gapSize={24} />
                </MDBox>
              </Card>
            </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewUser;
