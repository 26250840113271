import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {listUsers} from "services/service.ts";
import MDButton from "components/MDButton";

// Data
import transformdata from "layouts/users/data/tableData";
import getColumns from "layouts/users/data/columns";
import {useNavigate } from 'react-router-dom';

function Users() {

  const navigate = useNavigate();
  const goToPosts = (id) =>
    navigate({
      pathname: '/view-user/'+id,
  });

  const fetchIdRef = React.useRef(0);
  const [data, setData] = React.useState([]);
  const [isRefreshing, setRefreshing] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const columns = getColumns();

  const fetchData = React.useCallback(async ({ pageIndex, pageSize }) => {
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setRefreshing(true);

    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        console.log(pageSize, startRow, pageIndex);
        const userData = await listUsers(pageSize, startRow);
        
        if(userData.successful) {
          // console.log(reservationData.reservations);
          setData(transformdata(userData.users, goToPosts));
          setPageCount(Math.ceil(userData.total / pageSize));
          setRefreshing(false);
        } else {
          console.log(userData.errorMsg.status);
          setRefreshing(false);
        }
    }
  }, []);

  return (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                  </MDBox>
                  <Card>
                    <MDBox pt={3}>
                      <DataTable
                        datacolumns={columns}
                        datarows={data}
                        fetchData={fetchData}
                        pageCount={pageCount}
                        isSorted={false}
                        entriesPerPage={{ "defaultValue": 15, "entries": [5, 10, 15, 20, 25] }}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
  );
}

export default Users;
