import {LoginResult, PlatformUser, UserData, SingleUserData} from "../model/User";
import {ErrorMessage, StandardRespond} from "../model/ErrorMessage";
import {getToken} from "../userToken";
import {SingleLessonData} from "../model/Lesson";
import {parseUser, formatDateTime, parsePredictionResponse, parsePredictionAdminResponse} from "../services/parser";
import { A } from "@fullcalendar/core/internal-common";
import { ImageType } from "react-images-uploading";
import { PredictionAdminResponse, PredictionAdminResponseData, PredictionResponse, PredictionResponseData, SinglePredictionData } from "model/PredictionRequest";
import { AnalyticsResponse, AnalyticsResponseData, SingleAnalyticsResponseData } from "model/Analytics";
const config = {
  // URL: "http://localhost:8080",
  URL: process.env.REACT_APP_ENVIRONMENT == "dev" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL
};

export async function totalUpload(start: string, end: string): Promise<SingleAnalyticsResponseData> {
  const results: PredictionAdminResponse[] = [];

  const urlParams = new URLSearchParams();
  start && urlParams.set("start", start!.toString());
  end && urlParams.set("end", end!.toString());
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/analytics/uploads${paramsString}`);

    const dataJsonForm = await response.json();
    // console.log(dataJsonForm);
    const res: SingleAnalyticsResponseData = {successful: true}

    if (response.ok) {
      const resp: AnalyticsResponse = {
        key: dataJsonForm["key"],
        value: dataJsonForm["value"]
      };
      res.result = resp;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }
    return res;
  } catch (error) {
    throw error;
  }
}

export async function totalUser(start: string, end: string): Promise<SingleAnalyticsResponseData> {
  const results: PredictionAdminResponse[] = [];

  const urlParams = new URLSearchParams();
  start && urlParams.set("start", start!.toString());
  end && urlParams.set("end", end!.toString());
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/analytics/users${paramsString}`);

    const dataJsonForm = await response.json();
    // console.log(dataJsonForm);
    const res: SingleAnalyticsResponseData = {successful: true}

    if (response.ok) {
      const resp: AnalyticsResponse = {
        key: dataJsonForm["key"],
        value: dataJsonForm["value"]
      };
      res.result = resp;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }
    return res;
  } catch (error) {
    throw error;
  }
}

export async function detectionBreakdown(start: string, end: string): Promise<AnalyticsResponseData> {
  const results: AnalyticsResponse[] = [];

  const urlParams = new URLSearchParams();
  start && urlParams.set("start", start!.toString());
  end && urlParams.set("end", end!.toString());
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/analytics/detection-breakdown${paramsString}`);

    const dataJsonForm = await response.json();
    // console.log(dataJsonForm);
    const res: AnalyticsResponseData = {successful: true}

    if (response.ok) {
      (dataJsonForm as [any]).forEach((epc) => {
        const resp: AnalyticsResponse = {
          key: epc["key"],
          value: epc["value"]
        };
        results.push(resp);
    });

      res.results = results;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }
    return res;
  } catch (error) {
    throw error;
  }
}

export async function uploadOvertime(start: string, end: string): Promise<AnalyticsResponseData> {
  const results: AnalyticsResponse[] = [];

  const urlParams = new URLSearchParams();
  start && urlParams.set("start", start!.toString());
  end && urlParams.set("end", end!.toString());
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/analytics/upload-overtime${paramsString}`);

    const dataJsonForm = await response.json();
    const res: AnalyticsResponseData = {successful: true}

    if (response.ok) {
      (dataJsonForm as [any]).forEach((epc) => {
        const resp: AnalyticsResponse = {
          key: epc["key"],
          value: epc["value"]
        };
        results.push(resp);
    });

      res.results = results;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }
    return res;
  } catch (error) {
    throw error;
  }
}

export async function listUploadsAdmin(limit: number, offset: number, id: string): Promise<PredictionAdminResponseData> {
  const results: PredictionAdminResponse[] = [];

  const urlParams = new URLSearchParams();
  limit && urlParams.set("limit", limit!.toString());
  offset && urlParams.set("offset", offset!.toString());
  if (id != "") {
    urlParams.set("user_id", id);
  }
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/history-admin${paramsString}`);

    const dataJsonForm = await response.json();
    // console.log(dataJsonForm);
    const res: PredictionAdminResponseData = {total: 0, successful: true}

    if (response.ok) {
      (dataJsonForm["rows"] as [any]).forEach((epc) => {
          // console.log(epc);
          results.push(parsePredictionAdminResponse(config.URL!, epc));
      });
      res.response = results;
      res.total = dataJsonForm["total"]
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }
    return res;
  } catch (error) {
    throw error;
  }
}

export async function listUploads(limit: number, offset: number): Promise<PredictionResponseData> {
  const results: PredictionResponse[] = [];

  const urlParams = new URLSearchParams();
  limit && urlParams.set("limit", limit!.toString());
  offset && urlParams.set("offset", offset!.toString());
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/history${paramsString}`);

    const dataJsonForm = await response.json();
    // console.log(dataJsonForm);
    const res: PredictionResponseData = {total: 0, successful: true}

    if (response.ok) {
      (dataJsonForm["rows"] as [any]).forEach((epc) => {
          // console.log(epc);
          results.push(parsePredictionResponse(config.URL!, epc));
      });
      res.response = results;
      res.total = dataJsonForm["total"]
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getResultByID(id: string): Promise<SinglePredictionData> {
  try {
    const response = await get(`api/v1/analysis-by-id/${id}`);

    const dataJsonForm = await response.json();
    const res: SinglePredictionData = {successful: true}

    if (response.ok) {
      res.response = parsePredictionResponse(config.URL!, dataJsonForm);;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }

    return res;
  } catch (error) {
    throw error;
  }
}

export async function uploadImageCNN(image: ImageType, requestID: number): Promise<SinglePredictionData> {
  const url = `${config.URL}/api/v2/predict`;

  let token = '';
  token = getToken();

  const formData = new FormData();
  formData.append('photos', image.file!, "upload");
  formData.append('metadata', JSON.stringify({"request": requestID}));

  try {
    const requestConfig = {
      method: "POST",
      headers: {
        // Accept: contentType,
        // "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, requestConfig);
    const dataJsonForm = await response.json();
    const res: SinglePredictionData = {successful: true}

    if (response.ok) {
        const response: PredictionResponse = {
          id: dataJsonForm["status"]["id"],
          imagePath: `${config.URL}/api/v1/images?name=`+dataJsonForm["status"]["image_path"],
          status: dataJsonForm["status"]["status"],
          result: dataJsonForm["status"]["result"],
          createdDate: formatDateTime(dataJsonForm["status"]["create_date"]),
          shortName: dataJsonForm["status"]["disease_short_code"],
          diseaseName: dataJsonForm["status"]["disease_full_name"],
          fullDescription: dataJsonForm["status"]["disease_description"],
        };
        res.response = response;
    } else {
        const errorMsg: ErrorMessage = {
            status: dataJsonForm["status"],
        }
        res.successful = false;
        res.errorMsg = errorMsg;
    }

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function searchForUser(searchType: string, userType: string, limit: number, offset: number): Promise<StandardRespond> {
  try {
    const results: any[] = [];

    const urlParams = new URLSearchParams();
    limit && urlParams.set("limit", limit!.toString());
    offset && urlParams.set("offset", offset!.toString());
    userType && urlParams.set("user_type", userType!.toString());
    searchType && urlParams.set("search", searchType!.toString());
    const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

    const response = await get(`api/v1/users${paramsString}`);
    const dataJsonForm = await response.json();
    const res: StandardRespond = {successful: true}

    if (response.ok) {
        (dataJsonForm["rows"] as [any]).forEach((epc) => {
            results.push({
              value: epc["id"],
              label: epc["first_name"] + " " + epc["second_name"],
              email: epc["email"],
            });
        });
        res.results = results;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }

    return res;
  } catch (error) {
    throw error;
  }
}


export async function getUserByID(id: string): Promise<SingleUserData> {
  try {
    const response = await get(`api/v1/user-by-id/${id}`);

    const dataJsonForm = await response.json();
    const res: SingleUserData = {successful: true}

    if (response.ok) {
      res.user = parseUser(dataJsonForm);;
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }

    return res;
  } catch (error) {
    throw error;
  }
}

export async function listUsers(limit: number, offset: number): Promise<UserData> {
  const users: PlatformUser[] = [];

  const urlParams = new URLSearchParams();
  limit && urlParams.set("limit", limit!.toString());
  offset && urlParams.set("offset", offset!.toString());
  // startDate && urlParams.set("start_date", startDate!.toString());
  // endDate && urlParams.set("end_date", endDate!.toString());
  const paramsString = urlParams.toString() ? `?${urlParams.toString()}` : "";

  try {
    const response = await get(`api/v1/users${paramsString}`);

    const dataJsonForm = await response.json();
    const res: UserData = {total: 0, successful: true}

    if (response.ok) {
      (dataJsonForm["rows"] as [any]).forEach((epc) => {
          users.push(parseUser(epc));
      });
      res.users = users;
      res.total = dataJsonForm["total"]
    } else {
      const errorMsg: ErrorMessage = {
          status: dataJsonForm["status"],
      }
      res.successful = false;
      res.errorMsg = errorMsg;
    }

    return res;
  } catch (error) {
    throw error;
  }
}

export async function completeSignup(email: string, password: string, code: string): Promise<StandardRespond> {
  try {
    let response = await post("api/v2/complete-invite", {
                      "code": code,
                      "password": password,
                      "email": email
                   }, false);

    const json = await response.json();
    let result : StandardRespond = {
      successful: true,
    }

    if (response.ok) {
      result.successful = true;
    } else {
      const errorMsg: ErrorMessage = {
        status: json["status"],
      }
      result.successful = false;
      result.errorMsg = errorMsg;
    }

    return result;
  } catch (error) {
    let result : SingleLessonData = {
      successful: false,
    }
    return result;
  }
}


export async function login(signInfo: object) {
  try {
    let response = await post("api/v1/tkn/login", signInfo, false);
    
    const json = await response.json();
    let result : LoginResult = {
      successful: true,
    }

    if (response.ok) {
      const login: PlatformUser = {
        token: json["token"],
        id: json["id"],
        email: json["email"],
        firstName: json["first_name"],
        secondName: json["second_name"],
        dob: json["dob"],
        userType: json["is_admin"] ? "admin": "client",
        active: json["active"] ? "Active": "Inactive",
        createDate: json["create_date"],
      };
      result.login = login;
    } else {
      const errorMsg: ErrorMessage = {
        status: json["status"],
      }
      result.successful = false;
      result.errorMsg = errorMsg;
    }

    return result;

  } catch (error) {
    let result : LoginResult = {
      successful: false,
    }
    return result;
  }
}

async function post(endpoint: string, body: Object, requireAuth: boolean) {
  const url = `${config.URL}/${endpoint}`;
  const contentType = "application/json";

  let token = '';
  if (requireAuth) {
    token = getToken();
  }

  try {
    const requestConfig = {
      method: "POST",
      headers: {
        Accept: contentType,
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, requestConfig);
    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function put(endpoint: string, body: Object) {
  const url = `${config.URL}/${endpoint}`;
  const contentType = "application/json";
  try {
    const requestConfig = {
      method: "PUT",
      headers: {
        Accept: contentType,
        "Content-Type": contentType,
        Authorization: "Basic Y2FyYm9uTWFuVXNlcjpjYXJib25hbmR0aGV3b3JsZGlz",
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(url, requestConfig);
    const json = await response.json();

    if (response.ok) {
      return json;
    } else {
      console.error(response);
      throw "Request failed";
    }
  } catch (error) {
    throw error;
  }
}

export async function get(endpoint: string, params?: Map<string, any>)  {
  let url = `${config.URL}/${endpoint}`;

  if (params) {
    const paramKeys = Array.from(params.keys());
    const urlParams = new URLSearchParams();
    paramKeys.forEach((key) => urlParams.append(key, params.get(key)));
    url = `${url}?${urlParams.toString()}`;
  }
  const token = getToken()
  try {
    const requestConfig = {
      method: 'GET',
      // mode: 'no-cors',
      headers: {
        // Accept: contentType,
        "Content-Type": 'text/plain',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, requestConfig);
    return response
  } catch (error) {
    console.log(`GET Error: ${endpoint} `, error);
    throw error;
  }
}
