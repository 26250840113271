/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useMemo, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import {useNavigate } from 'react-router-dom';
import {completeSignup} from "services/service.ts";

function Cover() {

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        {showAlert.msg}
      </MDTypography>
    </MDTypography>
  );

  const [showAlert, setShowAlert] = useState({alert: false, msg: "", color: "secondary"});
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [dob, setDob] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = ({ target: { value } }) => setEmail(value);
  const handlePassword = ({ target: { value } }) => setPassword(value);
  const handleDob = ({ target: { value } }) => setDob(value);
  const handleFirstName = ({ target: { value } }) => setFirstName(value);
  const handleSecondName = ({ target: { value } }) => setSecondName(value);

  const checkFieldsValues = () => {
    if (email == "" || password == "" || code == "") {
      return "email or password or code cannot be empty";
    }
    return ""
  }

  const postSignup = (email, password, code) => {
    completeSignup(email, password, code).then((createLessonResp) =>{
      let msg = "";
      let color = "success";
      if(createLessonResp.successful) {
        msg = "Successful completed signup, head to login screen";
      } else {
        console.log(createLessonResp.errorMsg);
        msg = "Unable to complete signup, try again"
        color = "warning";
      }
      setShowAlert({msg: msg, alert: true, color: color});
    })
  };

  const handleInput = () => {
    const msg = checkFieldsValues();
    if(msg != "") {
      setShowAlert({msg: msg, alert: true});
      window.setTimeout( () => {
        setShowAlert({msg: "", alert: false});
      }, 2000);
      return;
    }
    postSignup(email, password, code);
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
        </MDBox>
        <MDBox>
            {
                showAlert.alert ? 
                <MDAlert color="secondary" dismissible>
                    {alertContent()}
                </MDAlert>: <MDBox></MDBox>
            }
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput onChange={handleEmail} value={email} type="email" label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={handleFirstName} value={firstName} type="firstName" label="First Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={handleSecondName} value={secondName} type="secondName" label="Second Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={handleDob} value={dob} type="text" label="Date of birth" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={handlePassword} value={password} type="password" label="Password" variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleInput} variant="gradient" color="info" fullWidth>
                sign up
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
