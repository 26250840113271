/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";

import { HandymanOutlined, VisibilityRounded} from '@mui/icons-material';

export default function transformdata(data) {
  return data.map(function(res) {
        return {
          description: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.description}
            </MDTypography>
          ),
          subject: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.subject}
            </MDTypography>
          ),
          tutor: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.tutor}
            </MDTypography>
          ),
          when: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.distance}
            </MDTypography>
          ),
          startdate: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.startDate}
            </MDTypography>
          ),
          enddate: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.endDate}
            </MDTypography>
          ),
          status: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {res.status}
            </MDTypography>
          ),
          // car: <Job title={res.carPlateNumber} extra={res.carBrand} description={res.carMake} />,
          action: (
            <MDTypography component="a" href="#" color="text">
              <IconButton
                size="small"
                disableRipple
                onClick={() => window.location.href = '/view-reservation/'+res.id }
              >
                <VisibilityRounded fontSize="small">
                  View
                </VisibilityRounded>
              </IconButton>

              {
                res.status == "new" ?
                <IconButton
                  size="small"
                  disableRipple
                  onClick={handleClickOpen}
                >
                  <HandymanOutlined fontSize="small">
                    View
                  </HandymanOutlined>
                </IconButton> : <MDTypography></MDTypography>
              }
            </MDTypography>
          ),
        }
    });
}
