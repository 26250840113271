export default function getColumns() {
    return [
        // { Header: "id", accessor: "id", width: "45%", align: "left" },
        // { Header: "car", accessor: "car", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Names", accessor: "names", align: "left" },
        { Header: "Type", accessor: "userType", align: "left" },
        { Header: "status", accessor: "status", align: "left" },
        { Header: "action", accessor: "action", align: "left" },
    ];
}