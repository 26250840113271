/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";

function ProductInfo({data}) {
  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          Image results
        </MDTypography>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Detected
        </MDTypography>
      </MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {data.diseaseName}
        </MDTypography>
      </MDBox>
      {/* <MDBadge variant="contained" color="success" badgeContent="in stock" container /> */}
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description
        </MDTypography>
      </MDBox>
      <MDBox component="ul" m={0} pl={4} mb={2}>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
            {data.fullDescription}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default ProductInfo;
