export function getColumns() {
    return [
        { Header: "result", accessor: "result", width: "55%" },
        { Header: "status", accessor: "status" },
        { Header: "Disease Name", accessor: "diseaseName", align: "center" },
        { Header: "Created Date", accessor: "createdDate", align: "center" },
      ];
}

export function getAdminColumns() {
  return [
      { Header: "user", accessor: "user", width: "25%" },
      { Header: "result", accessor: "result", width: "25%" },
      { Header: "status", accessor: "status" },
      { Header: "Disease Name", accessor: "diseaseName", align: "center" },
      { Header: "Created Date", accessor: "createdDate", align: "center" },
    ];
}